.ControlArrows {
    font-size: 2rem;
    color: #339933;
    user-select: none;
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    background-color: rgb(241, 248, 235);
    z-index: 5;

}

.ControlArrows a:hover {
    cursor: pointer;
}