.DownloadDiv {
    position: absolute;
    right: 0;
    color: green;
    font-weight: bold;
    opacity: 0.6;
    z-index: 4;
    height: 30%;
    width: 20%;
    bottom: -10px;
    font-size: 1rem;
}
.FileLinks:hover {
    color: #F8C626;
    font-style: italic;
    cursor: pointer;
}
.RemoveCross:hover {
    font-size: 0.5rem;
    cursor: pointer;
}