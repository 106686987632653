.CellMenu {
    position: absolute;
    /*border: 2px solid #339933;*/
    border-radius: 5px;
    top: 0;
    left: 0;
    background-color: #339933;
    width: 60px;
    z-index: 3;
    color: #ffffff;
    font-weight: bold;
    padding: 5px;
}

.CellMenu div {
    display: block;
    margin-top: 2px;
}
.CellMenu div:hover {
    background-color: #F8C626;
}