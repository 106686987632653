.Date {
    display: inline-block;
    margin-top: 5px;
    width: 40px;
    font-weight: 900;
    color: #339933;
    border: solid 2px;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
}
.Weekends {
    display: inline-block;
    margin-top: 5px;
    width: 40px;
    font-weight: 900;
    background-color: #339933;
    color: #ffffff;
    border: solid 2px ;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
}
.Weekends:hover, .Date:hover, .Homeless:hover {
    cursor: pointer;
}
.Flex-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}
.HiddenDiv {
}
.BuildingArrows {
    font-size: 2rem;
    display: inline-block;
    position: absolute;
    z-index: 1;
    bottom: -5px;
}
.BuildingArrows:hover {
    font-size: 200%;
    cursor: pointer;
}
.BuildingName:hover{
    cursor: pointer;
}

@media only screen and (max-width: 1450px) {
    .HiddenDiv { display: none !important; }
}
@media print {
    .HiddenDiv {display:none;}
}