.DeleteEmployee {
    margin-top: 20px;
    border: groove 1px #F8C626;
    border-radius: 10px;
}
.SelectName {
    width: 250px;
    height: 25px;
    font-size: 1.2rem;
    border-radius: 4px;
    border: solid 2px #339933;
    font-size: 1rem;
    margin: 5px;
}
.ButtonR {
    width: 100px;
    height: 25px;
    margin: 10px;
    background-color: red;
}
.ButtonR:hover {
    background-color: black;
    color: white;
}
.ButtonW {
    width: 100px;
    height: 25px;
    margin: 10px;
    background-color: gray;
}
.ButtonW:hover {
    background-color: white;
    color: black;
}
@media print {
    .DeleteEmployee {display:none;}
}