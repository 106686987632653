.NavItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavItem a {
    color: #F8C626;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavItem a:hover,
.NavItem a:active,
.NavItem a.active {
    color: #000000;
}

@media (min-width: 599px) {
    .NavItem {
        margin: 0;
        width: auto;
        display: flex;
        height: 100%;
        align-items: center;
    }

    .NavItem a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }

    .NavItem a:hover,
    .NavItem a:active,
    .NavItem a.active {
        background-color: #F8C626;
        border-bottom: 4px solid #AAAAAA;
        color: white;
    }


}