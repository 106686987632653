.Building {
    width: 1415px;
    height: 350px;
    border: black 2px solid;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 2px;
    margin: 20px auto 30px;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.Floor {
    height: 150px;
    border: black 2px solid;
    border-radius: 3px;
    margin: auto;
}

.Room {
    height: 50px;
    width: 50px;
    display: inline-block;
    box-sizing: border-box;
    background-color: #339933;
    border: black 2px solid;
    padding-top: 10px;
    position: relative;
}
.OnDoubleClick {
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    padding: 5px;
    width: auto;
    opacity: 0.8;
    border-radius: 5px;
    background: grey;
    left: -100px;
    top: -5px;
    z-index: 101;
    color: white;
}
.RoomOv {
    background-color: red;
}
.RoomF {
    background-color: cornflowerblue;
}
.RoomHF {
    background-color: #ead126;
}
.NotForGuests {
    background-color: white;
}
.Corridor {
    height: 50px;
    box-sizing: border-box;
    margin: auto;
    padding: 10px;
}
.RoomDragOver {
    animation: blinking 1s infinite;
}
@keyframes blinking {
    0% {
        background-color: #339933;
    }
    100% {
        background-color: white;
    }
}