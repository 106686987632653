.Button {
    display: inline-block;
    margin: 10px;
    border-radius: 10px;
    border: #aaaaaa 2px solid;
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    outline: none;
    font: inherit;
    font-weight: bold;
}

.Button:disabled {
    background-color: #ccc;
    color: #5f5c5c;
    cursor: not-allowed;
}

.Approve {
    background-color: #339933;
}
.Reject {
    background-color: red;
}
.Fulfilled{
    background-color: #F8C626;
}
