.UploadDiv {
    position: absolute;
    right: 0;
    color: gray;
    opacity: 0.8;
    z-index: 3;
    height: 30%;
    width: auto;
    bottom: -40px;
    font-size: 1.2rem;
}