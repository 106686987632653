.Button {
    width: 100px;
    height: 25px;
    margin: 10px;
    background-color: #F8C626;
}
.Button:hover {
    background-color: #339933;
    color: white;
}
.ButtonDisabled {
    width: 100px;
    height: 25px;
    margin: 10px;
    background-color: #cccccc;
    color: #cccccc;
}
@media print {
    .Button {visibility: hidden}
    .ButtonDisabled {display: none}
}
