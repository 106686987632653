.PersonalData {
    width: 100%;
    border: 1px solid #74f34a;
    border-radius: 5px;
    text-align: left;
    padding: 8px;
}

.PersonalData input {
    width: 210px;
}

.Itineraries {
    margin-top: 5px;
    width: 100%;
    border: 1px solid #ead126;
    border-radius: 5px;
    text-align: left;
    padding: 1px;
}
.Itineraries input {
    width: 210px;

}
.Itineraries th {
    width: 90%;
    text-align: center;
}

.Anchor {
    margin: 0 5px;
}

.Anchor:hover {
    cursor: pointer;
}
.Hotel {
    margin-top: 5px;
    width: 100%;
    border: 1px solid #74f34a;
    border-radius: 5px;
    text-align: left;
    padding: 1px;
}