.Inmate {
    width: auto;
    display: inline-block;
    border-radius: 5px;
    border: #F8C626 3px solid;
    box-shadow: 4px 4px 3px #aaaaaa;
    margin: 5px;
    padding: 5px;
    box-sizing: border-box;
    position: relative;
}
.Inmate:hover .HiddenDiv {
    display: inline-block;
}
.HiddenDiv {
    display: none;
    box-sizing: border-box;
    position: absolute;
    padding: 5px;
    width: auto;
    opacity: 0.8;
    border-radius: 5px;
    background: grey;
    left: -100px;
    top: -5px;
    z-index: 100;
    color: white;
}
