.TimeSheet {
    position: relative;
    width: 100%;
    padding: 0 5px 5px 5px;
    box-sizing: border-box;
}

@media (max-width: 1600px){
    .TimeSheet {
        width: 900px;
        margin: auto;
    }
}
@media (max-width: 900px){
    .TimeSheet {
        width: 320px;
        margin: auto;
    }
}
@media print
{
    .TimeSheet
    {
        width: 110%;
        margin: 0;
    }
}