.Auth {
    margin-top: 20px;
    display: inline-block;
    text-align: center;
}
.Fields {
    width: 250px;
    height: 25px;
    border-radius: 4px;
    border: solid 2px #339933;
    font-size: 1rem;
    margin: 10px auto;
    display: block;
}

.Button {
    width: 100px;
    height: 25px;
    margin: 10px;
    background-color: #F8C626;
}
.Button:hover {
    background-color: #339933;
    color: white;
}
.Error {
    color: red;
    font-size: 1.5rem;
}