.Container {
    margin: auto 30px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
}
.Buttons {
    margin: 10px;
    position: relative;
    bottom: -170px;
}
.Input {
    margin-bottom: 10px;
    height: 42px;
}
.Form {
    position: relative;
    top: 40%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media screen and (max-width: 600px) {
    .Container {
        position: relative;
        top: -70%;
        grid-template-columns: auto;
        grid-auto-flow: row;
    }
    
}