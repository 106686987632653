.TimeCell {
    border: 2px solid #F8C626;
    border-radius: 4px;
    width: 40px;
    height: 23px;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    user-select: none;

}
.Highlighted {
    background-color: #339933;
}