.Report {
    position: fixed;
    z-index: 300;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    left: 15%;
    top: 60px;
    box-sizing: border-box;
    transition: all 0.5s ease-out;
    height: 90%;
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 30%;
}

.Backdrop {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, 0.5);
}
.BuildingTable {
    border-collapse:collapse;
    table-layout:fixed;
    position: absolute;
    border: black 3px solid;
    /*border-radius: 10px;*/
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}
.Stats {
    position: absolute;
    top: 1000px;
    text-align: center;
    display: block;
    width: 100%;
}

.BuildingTable td {
    border: grey 1px solid;
    max-width: 80px;
    height: 35px;
    font-size: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media (max-width: 1500px) {
    .Report {
        display: none;
        /*left: calc(50% - 250px);*/
    }
}