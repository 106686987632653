.AddNewEmployee {
    margin-top: 20px;
    border: groove 1px #F8C626;
    border-radius: 10px;
}

.InputFields {
    width: 250px;
    height: 25px;
    font-size: 1.2rem;
    border-radius: 4px;
    border: solid 2px #339933;
    font-size: 1rem;
    margin: 5px;
}
.Button {
    width: 100px;
    height: 25px;
    margin: 10px;
    background-color: #F8C626;
}
.Button:hover {
    background-color: #339933;
    color: white;
}
@media print {
    .AddNewEmployee {display:none;}
}