.TimeSheet {
    background-color: #92f394;
    display: inline-block;
}
.TimeTable {
    margin: 5px 3px;
    text-align: center;
}
.Department {
    padding: 10px 5px 0px 10px;
    display: block;
    font-weight: 500;
    font-size: large;
    text-decoration: underline;
    text-align: center;
    position: relative;
}
.Department:hover {
    cursor: pointer;
}


.OnePerson {
    display: block;
    margin-top: 2px;
}

.NameSearch {
    text-align: left;
    padding: 0 5px 0 0px;
    display: inline-block;
    width: 300px;
}
.HeadOfTable {
    padding: 5px 0 5px 0;
    margin-bottom: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    width: auto;
    display: block;
    background-color: rgb(241, 248, 235);
    z-index: 3;
    /*margin: auto;*/
    /*border: 1px solid #cccccc;*/
    /*box-shadow: 0 2px 5px 0 #000000;*/
}

.Dates {
    padding: 5px 2px 5px 0;
    display: inline-block;
}
.Date {
    display: inline-block;
    margin-top: 5px;
    width: 40px;
    font-weight: 900;
    color: #339933;
    border: solid 2px;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
}
.NameSearch input {
    width: 250px;
    height: 20px;
    font-size: 1.2rem;
    border-radius: 4px;
    border: solid 2px #339933;
}
.OverWork {
    display: inline-block;
    font-weight: bolder;
    font-size: 0.8rem;
    position: relative;
    width: 25px;
}
.Hidden {
    display: none;
    box-sizing: border-box;
    position: absolute;
    padding: 5px;
    width: auto;
    opacity: 0.8;
    border-radius: 5px;
    background: grey;
    left: -100px;
    top: -5px;
    z-index: 100;
    color: white;
}
.OverWork:hover .Hidden {
    display: inline-block;
}
.Weekends {
    display: inline-block;
    margin-top: 5px;
    width: 40px;
    font-weight: 900;
    background-color: #339933;
    color: #ffffff;
    border: solid 2px ;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
}
.Holidays {
    display: inline-block;
    margin-top: 5px;
    width: 40px;
    font-weight: 900;
    background-color: #F8C626;
    color: #000000;
    border: solid 2px #339933;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
}

.Weekends:hover, .Holidays:hover, .Date:hover {
    cursor: pointer;
}


.FromRight {
    animation-name: fromright;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: initial;
    animation-direction: initial;
    position: relative;
    overflow: hidden;
}

@keyframes fromright {
    from {left:-100%}
    to {left: 0%}
}
.FromLeft {
    animation-name: fromleft;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: initial;
    animation-direction: initial;
    position: relative;
}

@keyframes fromleft {
    from {right: -100%}
    to {right: 0}
}
@media print {
    .HeadOfTable {display:none;}
}