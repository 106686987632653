.Card {
    width: 80%;
    min-width: 300px;
    border-radius: 10px;
    border: #F8C626 3px solid;
    box-shadow: 4px 4px 3px #aaaaaa;
    margin: 30px auto;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}
.Delete {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: red;
}
.Outdated {
    position: absolute;
    bottom: 5px;
    left: 5px;
    cursor: pointer;
    color: red;
}
.ItineraryTable {
    width: 100%;
    border-collapse: collapse;
}

.ItineraryTable th {
    font-size: 0.8rem;
}

.ItineraryTable td, .ItineraryTable th {
    border: 1px solid #dddddd;
    padding: 5px;
    text-align: center;
}
.Canceled {
    position: absolute;
    left: 5%;
    user-select: none;
    color: darkred;
    opacity: 0.7;
    z-index: 2;
    height: 100%;
    width: 90%;
    font-size: 3rem;
}

@media all and (max-width: 760px)
{

    .ItineraryTable {
        display: block;
    }


    .ItineraryTable tr {
        display: list-item;
        border: none;
        border-top: solid thin #F8C626;
    }

    .ItineraryTable th {
        display: inline-block;
        border: solid thin gray;
        margin-right: 0.2rem;
    }

    .ItineraryTable td {
        display: inline-block;
        border: solid thin gray;
        margin-right: 0.2rem;
    }

}

